import React from 'react';
import { GoQuote } from 'react-icons/go';

import Styles from './sub-page-client-testimonial.module.scss';

export default ( props ) => (
  <section className={Styles.section}>
    <div className="container">
      <div className={Styles.container}>
        <h2 className={Styles.title}>Testimonials</h2>
        <p className={Styles.text}>
          <span className={Styles.quote}>
            <GoQuote />
          </span>
          {props.quote}
        </p>
        <h3 className={Styles.name}>{props.name}</h3>
        <p className={Styles.position}>{props.position}</p>
      </div>
    </div>
  </section>
);
