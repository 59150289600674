import React from 'react';

/**
 * Images/SVG
 */
import Background from '../../images/inner-page.png';
import UsiLogo from '../../images/usi-logo.png';
import Wordpress from '../../images/wordpress-logo.png';
import Css from '../../images/css.png';
import Php from '../../images/php.png';
import Html from '../../images/html.png';
import Js from '../../images/js.png';
import UsimoneyImg1 from '../../images/usimoney.png';
import UsimoneyImg2 from '../../images/usimoney1.png';
import UsimoneyImg3 from '../../images/usimoney2.png';

/**
 * Components
 */
import SEO from '../../components/seo/seo';
import Layout from '../../components/layout/layout';
import DefaultPagesBanner from '../../components/default-banner/default-banner';
import Company from 
  '../../components/sub-page-client-company/sub-page-client-company';
import Testimonial from 
  '../../components/sub-page-client-testimonial/sub-page-client-testimonial';
import SeeMore from 
  '../../components/sub-page-client-see-more/sub-page-client-see-more';
import Brief from 
  '../../components/sub-page-client-brief/sub-page-client-brief';
import Challenge from 
  '../../components/sub-page-client-challenge/sub-page-client-challenge';

export default ( props ) => {

  /**
   * Banner
   */
  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'USI Money',
    mainText: 'USI Money'
  };

  /**
   * Company data
   */
  const descriptionData = {
    link: 'https://www.usimoney.com/',
    logo: <img alt="usi logo" src={UsiLogo}/>,
    titleDescription: 
      'USI MONEY – Delivering digital transformation across all platforms',
    description: 
      'USI Money is a unique foreign exchange provider offering bespoke solutions to both individual and corporate clients. USI Money is the ideal partner to trade your FX with. They hold the one of the biggest Foreign Exchange markets in the World.',
    tech:[
      <img alt="wordpress logo" src={Wordpress} />,
      <img alt="css logo" src={Css}/>,
      <img alt="js logo" src={Js}/>,
      <img alt="html logo" src={Html}/>,
      <img alt="php logo" src={Php}/>
    ]
  };

  /**
   * Brief Data
   */
  const briefData = {
    description: 
      'They reached out to us in order to create a platform. We were to provide a vision of a strategy for upcoming  digital money transfers revolution.',
    images: [
      <img alt="usimoney" src={UsimoneyImg2} />,
      <img alt="usimoney" src={UsimoneyImg3} />
    ],
    scope: [
      'Marketing/landing page', 
      'Mobile apps for Android and iOS', 
      'Business logic automation',
      'Email campaigns'
    ]
  };

  /**
   * Challenge Data
   */
  const challengeData = {
    challenge: 
      'An essential aspect to USI Money is the multi-platform brand development which cuts across website and mobile apps. We are faced with the task of creating a new visual identity and brand that resonate with the offerings of USI Money.',
    solution: 
      'We created the new website that connected the nature of processes of the exchange. The layout and navigation helped provide impact, consistency and flexibility.',
    solutionPoint: false,
    outcome: 
      'A new website, brand logo, and marketing materials that supported USI as one of the biggest Foreign Exchange markets in the World. The transformation helped USI money gain competitive advantage in the connected world.',
    outcomePoint: false,
    image: <img alt="usimoney" src={UsimoneyImg1} />
  };

  /**
   * Testemonial data
   */
  const testimonialData = {
    quote: 
      'Excellent work on the usimoney.com website and Mobile app.',
    name: 'Khaleeq Taimuri',
    position: ''
  };

  /**
   * See more footer
   */
  const seeMoreData = {
    links: ['cps','jll','pro']
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={
          [`digital`, `agency`, `technology`, `Radium`, `usimoney`, `Client`]
        } 
      />
      <DefaultPagesBanner {...innerBannerData} />
      <Company {...descriptionData} />
      <Brief {...briefData} />
      <Challenge {...challengeData}/>
      <Testimonial {...testimonialData} />
      <SeeMore {...seeMoreData} />
    </Layout>
  )
};
